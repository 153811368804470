<template>
  <base-info editPath="userEdit" :baseInfoList="baseInfoList" :detailInfo="userInfo"></base-info>
</template>

<script>
import BaseInfo from '../BaseInfo'
export default {
  created () {
     this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
  },
  data(){
    return {
      userInfo:{},
      baseInfoList:[
        {
          title:"总包部名称",
          field:"name"
        },
        {
          title:"所属项目公司",
          field:"group_name"
        },
        {
          title:"管理员姓名",
          field:"people_name"
        },
        {
          title:"联系电话",
          field:"phone_number"
        },
        {
          title:"设置项目部总数",
          field:"department_num"
        },
        {
          title:"设置监督岗总数",
          field:"sentry_num"
        },
      ],
    }
  },

  components:{
    BaseInfo
  }
}
</script>

<style scoped>

</style>
